import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TablePagination, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Swal from 'sweetalert2';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useApi } from '../../contexts/Apicontext';
import { useNavigate } from 'react-router-dom';

const UsersTable = () => {
    const [users, setUsers] = useState([]);
    const [editUserId, setEditUserId] = useState(null);
    const [editUser, setEditUser] = useState({ name: '', email: '', password: '', role: '', isAdmin: false, signupDate: new Date(), lastLogin: new Date(), phoneNumber: '', countryCode: '' });
    const [newUser, setNewUser] = useState({ name: '', email: '', password: '', role: '', isAdmin: 0, signupDate: new Date(), lastLogin: new Date(), phoneNumber: '', countryCode: '' });
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const apiBaseUrl = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers();

        // `popstate` 이벤트 리스너 추가
        window.onpopstate = (event) => {
            if (event.state) {
                setPage(event.state.page);
                setRowsPerPage(event.state.rowsPerPage);
            }
        };
    }, []);

    useEffect(() => {
        // 현재 페이지네이션 상태를 히스토리에 저장
        window.history.pushState({ page, rowsPerPage }, '', '');
    }, [page, rowsPerPage]);

    const fetchUsers = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/users`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        setUsers(data.map(user => ({
            ...user,
            signupDate: new Date(user.signupDate),
            lastLogin: new Date(user.lastLogin)
        })));

        const totalPages = Math.ceil(data.length / rowsPerPage);
        if (page >= totalPages) {
            setPage(totalPages - 1);
        }
    };

    const handleEditClick = (user) => {
        setEditUserId(user.userID);
        setEditUser({
            name: user.name,
            email: user.email,
            password: user.password,
            role: user.role,
            isAdmin: user.isAdmin,
            signupDate: new Date(user.signupDate),
            lastLogin: new Date(user.lastLogin),
            phoneNumber: user.phoneNumber || '',
            countryCode: user.countryCode || ''
        });
        setOpenEditDialog(true);
    };

    const handleViewDetailsClick = (user) => {
        navigate(`/admin/users/${user.userID}/details`, { state: { user } });
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setEditUserId(null);
    };

    const handleSaveClick = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/users/${editUserId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(editUser)
        });

        if (response.ok) {
            Swal.fire('Saved!', 'User information has been updated.', 'success');
            handleCloseEditDialog();
            fetchUsers();
        } else {
            Swal.fire('Error!', 'Failed to update user information.', 'error');
        }
    };

    const handleDeleteClick = async (userId) => {
        const checkdelete = window.confirm("Are you sure you want to delete this user?");
        if (!checkdelete) {
            return;
        }
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/users/${userId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.ok) {
            Swal.fire('Deleted!', 'User has been deleted.', 'success');
            fetchUsers();
        } else {
            Swal.fire('Error!', 'Failed to delete user.', 'error');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditUser({ ...editUser, [name]: value });
    };

    const handleNewInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    };

    const handleAddUser = async () => {
        const token = localStorage.getItem('token');
        const response = await fetch(`${apiBaseUrl}/admin/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(newUser)
        });

        if (response.ok) {
            Swal.fire('Added!', 'New user has been added.', 'success');
            setNewUser({ name: '', email: '', password: '', role: '', isAdmin: 0, signupDate: new Date(), lastLogin: new Date(), phoneNumber: '', countryCode: '' });
            handleCloseAddDialog();
            fetchUsers();
        } else {
            Swal.fire('Error!', 'Failed to add new user.', 'error');
        }
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className='w-full'>
                <Typography variant="h4" gutterBottom>
                    Users Management
                </Typography>
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleOpenAddDialog} sx={{ mb: 2 }}>
                    Add New User
                </Button>
                <TableContainer component={Paper} sx={{ width: '100%' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>userID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Password</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Is Admin</TableCell>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>Country Code</TableCell>
                                <TableCell>Signup Date</TableCell>
                                <TableCell>Last Login</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                                <TableRow key={user.userID}>
                                    <TableCell>{user.userID}</TableCell>
                                    <TableCell>{user.name}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.password}</TableCell>
                                    <TableCell>{user.role}</TableCell>
                                    <TableCell>{user.isAdmin ? 'True' : 'False'}</TableCell>
                                    <TableCell>{user.phoneNumber}</TableCell>
                                    <TableCell>{user.countryCode}</TableCell>
                                    <TableCell>{user.signupDate.toLocaleString()}</TableCell>
                                    <TableCell>{user.lastLogin.toLocaleString()}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleViewDetailsClick(user)}>
                                            <VisibilityIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleEditClick(user)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteClick(user.userID)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={users.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

                {/* Dialog for editing and adding users omitted for brevity */}
            </div>
        </LocalizationProvider>
    );
};

export default UsersTable;
