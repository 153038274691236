import React, { useState } from 'react';
import {
    Box, Toolbar, Container, Typography, Button, Select, MenuItem, Grid, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, IconButton, Checkbox, FormControlLabel
} from '@mui/material';
import AdminHeader from '../AdminHeader';
import AdminMenuDrawer from '../AdminMenuDrawer';
import { useApi } from '../../../contexts/Apicontext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quillEditorStyles.css';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import AWS from 'aws-sdk';

const drawerWidth = 240;

// English TAGS and DETAILED_TAGS
const TAGS = [
    "Information and Ideas",
    "Expression of Ideas",
    "Craft and Structure",
    "Standard English Conventions"
];

const DETAILED_TAGS = {
    "Information and Ideas": {
        "Command of Evidence": [],
        "Inference": [],
        "Central Ideas and Details": []
    },
    "Craft and Structure": {
        "Words in Context": [],
        "Text Structure and Purpose": [],
        "Cross-Text Connections": []
    },
    "Expression of Ideas": {
        "Rhetorical Synthesis": [],
        "Transitions": []
    },
    "Standard English Conventions": {
        "Boundaries": [],
        "Form, Structure, and Sense": ["SubjectVerbAgreement", "PronounCases"]
    }
};



const BACKEND_URLS = {
    "Information and Ideas": {
        "Command of Evidence": "/generate-question/ENGLISH/INFOIDEAS/COMMANDEVIDENCE",
        "Inference": "/generate-question/ENGLISH/INFOIDEAS/INFERENCE",
        "Central Ideas and Details": "/generate-question/ENGLISH/INFOIDEAS/CENTRALIDEAS"
    },
    "Craft and Structure": {
        "Words in Context": "/generate-question/ENGLISH/CRAFTSTRUCTURE/WORDSINCONTEXT",
        "Text Structure and Purpose": "/generate-question/ENGLISH/CRAFTSTRUCTURE/TEXTSTRUCTURE",
        "Cross-Text Connections": "/generate-question/ENGLISH/CRAFTSTRUCTURE/CROSSTEXT"
    },
    "Expression of Ideas": {
        "Rhetorical Synthesis": "/generate-question/ENGLISH/EXPRESSION/RHETORICALSYNTHESIS",
        "Transitions": "/generate-question/ENGLISH/EXPRESSION/TRANSITIONS"
    },
    "Standard English Conventions": {
        "Boundaries": "/generate-question/ENGLISH/STANDARDS/BOUNDARIES",
        "Form, Structure, and Sense": {
            "SubjectVerbAgreement": "/generate-question/ENGLISH/STANDARDS/FORMSTRUCTURE/SUBJECTVERBAGREEMENT",
            "PronounCases": "/generate-question/ENGLISH/STANDARDS/FORMSTRUCTURE/PRONOUNCASES"
        }
    }
};

const difficultyMapping = {
    '하': 3,
    '중': 2,
    '상': 1,
    3: '하',
    2: '중',
    1: '상',
    'Easy': 3,
    'Medium': 2,
    'Hard': 1
};

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION
});

const s3 = new AWS.S3();

const uploadFileToS3 = (file, quizID, imageIndex, optionName) => {
    const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: `/AI-ENG/${quizID}/option-${optionName}-${imageIndex}-${file.name}`,
        Body: file,
    };

    return s3.upload(params).promise();
};

const AIAdminPage = () => {
    const [selectedTag, setSelectedTag] = useState('');
    const [selectedDetailedTag, setSelectedDetailedTag] = useState('');
    const [selectedSubDetailedTag, setSelectedSubDetailedTag] = useState('');
    const [difficulty, setDifficulty] = useState('');
    const [questionType, setQuestionType] = useState('multiple choice');
    const [includeImage, setIncludeImage] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editQuestion, setEditQuestion] = useState(null);
    const [numQuestions, setNumQuestions] = useState(1);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
    const [uploadedImageCount, setUploadedImageCount] = useState(0);
    const apiBaseUrl = useApi();

    const handleTagChange = (event) => {
        setSelectedTag(event.target.value);
        setSelectedDetailedTag('');
        setSelectedSubDetailedTag('');
    };

    const handleDetailedTagChange = (event) => {
        setSelectedDetailedTag(event.target.value);
        setSelectedSubDetailedTag('');
    };

    const handleGenerateQuestion = async () => {
        if (!selectedTag || !selectedDetailedTag || !difficulty || !questionType || numQuestions < 1) {
            alert('모든 필드를 선택해 주세요.');
            return;
        }

        // 서브 디테일 태그가 필요한 경우 선택 확인
        if (
            DETAILED_TAGS[selectedTag][selectedDetailedTag] &&
            DETAILED_TAGS[selectedTag][selectedDetailedTag].length > 0 &&
            !selectedSubDetailedTag
        ) {
            alert('하위 항목을 선택해 주세요.');
            return;
        }

        const requestData = {
            difficulty: difficulty,
            questionType: questionType,
            includeImage: includeImage,
        };

        // 백엔드 URL 결정
        let backendUrl;
        const detailedTagData = BACKEND_URLS[selectedTag]?.[selectedDetailedTag];

        if (typeof detailedTagData === 'string') {
            backendUrl = detailedTagData;
        } else if (typeof detailedTagData === 'object' && selectedSubDetailedTag) {
            backendUrl = detailedTagData[selectedSubDetailedTag];
        } else {
            alert('잘못된 태그 또는 세부 태그 선택입니다.');
            return;
        }

        setLoading(true);

        const token = localStorage.getItem('token');

        // Retry mechanism for API calls
        const fetchWithRetry = async (url, options, retries = 5, delay = 1000) => {
            for (let i = 0; i < retries; i++) {
                try {
                    const response = await fetch(url, options);
                    if (response.ok) {
                        return response;
                    } else if (response.status >= 500 && i < retries - 1) {
                        await new Promise(res => setTimeout(res, delay));
                        continue;
                    } else {
                        throw new Error(`Failed to fetch: ${response.statusText}`);
                    }
                } catch (error) {
                    if (i === retries - 1) {
                        throw error;
                    }
                    await new Promise(res => setTimeout(res, delay));
                }
            }
        };

        try {
            let generatedQuestions = [];
            for (let i = 0; i < numQuestions; i++) {
                const response = await fetchWithRetry(`${apiBaseUrl}${backendUrl}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(requestData)
                });
                const data = await response.json();

                // Map difficulty from number to string
                data.difficulty = difficultyMapping[data.difficulty] || 3;
                data.tag = selectedTag;
                data.detailed_tag = selectedDetailedTag;
                data.sub_detailed_tag = selectedSubDetailedTag;
                data.id = Date.now() + Math.random();
                generatedQuestions.push(data);
            }
            setQuestions(generatedQuestions);
        } catch (error) {
            alert(`Error: ${error.message}`);
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (question) => {
        const mappedEditQuestion = {
            ...question,
            difficulty: difficultyMapping[question.difficulty]
        };
        setEditQuestion(mappedEditQuestion);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setEditQuestion(null);
    };

    const handleSaveClick = async () => {
        const reverseDifficultyMapping = {
            '하': 3,
            '중': 2,
            '상': 1
        };

        const mappedEditQuestion = {
            ...editQuestion,
            difficulty: reverseDifficultyMapping[editQuestion.difficulty]
        };

        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${apiBaseUrl}/generate-question/English/save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(mappedEditQuestion)
            });

            if (response.ok) {
                alert('Question information has been updated.');
                handleCloseEditDialog();
                setQuestions(prevQuestions => prevQuestions.filter(q => q.id !== editQuestion.id));
            } else {
                alert('Failed to update question information.');
            }
        } catch (error) {
            alert('Error updating question information.');
            console.error(error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditQuestion({ ...editQuestion, [name]: value });
    };

    const handleContentChange = (index, field, value) => {
        const newContent = [...editQuestion.content];
        newContent[index][field] = value;
        setEditQuestion({ ...editQuestion, content: newContent });
    };

    const handleAddContent = () => {
        setEditQuestion({
            ...editQuestion,
            content: [...editQuestion.content, { type: 'text', value: '' }]
        });
    };

    const handleRemoveContent = (index) => {
        const newContent = [...editQuestion.content];
        newContent.splice(index, 1);
        setEditQuestion({ ...editQuestion, content: newContent });
    };

    const handleOptionChange = (index, field, value) => {
        const newOptions = [...editQuestion.options];
        newOptions[index][field] = value;
        setEditQuestion({ ...editQuestion, options: newOptions });
    };

    const handleAddOption = () => {
        setEditQuestion({
            ...editQuestion,
            options: [...editQuestion.options, { type: 'text', name: '', value: '' }]
        });
    };

    const handleRemoveOption = (index) => {
        const newOptions = [...editQuestion.options];
        newOptions.splice(index, 1);
        setEditQuestion({ ...editQuestion, options: newOptions });
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUploadClick = async (optionName, index, type) => {
        if (selectedFile) {
            try {
                const uploadResult = await uploadFileToS3(selectedFile, editQuestion.id, uploadedImageCount + 1, optionName);
                setUploadedFileUrl(uploadResult.Location);
                setUploadedImageCount(prev => prev + 1);
                alert('File has been uploaded to S3.');

                if (type === 'content') {
                    handleContentChange(index, 'value', uploadResult.Location);
                } else if (type === 'option') {
                    handleOptionChange(index, 'value', uploadResult.Location);
                }
            } catch (error) {
                alert('Failed to upload image.');
                console.error(error);
            }
        } else {
            alert('No file selected.');
        }
    };

    const renderContent = (question) => {
        // question.content가 배열인지 확인 후 map 사용
        if (!Array.isArray(question?.content)) {
            return null;  // undefined이거나 배열이 아닌 경우 null 반환
        }
        return question.content.map((item, index) => {
            if (item.type === 'text') {
                return (
                    <div key={index} className="prose">
                        <div dangerouslySetInnerHTML={{ __html: renderFormula(item.value) }} />
                    </div>
                );
            } else if (item.type === 'image') {
                return <img key={index} src={item.value} alt={`content-${index}`} style={{ maxWidth: '100%' }} />;
            } else {
                return null;
            }
        });
    };


    const renderOptions = (question) => {
        // question.options가 배열인지 확인 후 map 사용
        if (!Array.isArray(question?.options)) {
            return null;  // undefined이거나 배열이 아닌 경우 null 반환
        }
        return question.options.map((option, index) => {
            if (option.type === 'text') {
                return (
                    <div key={index} className="prose">
                        <div dangerouslySetInnerHTML={{ __html: `<strong>${option.name}</strong>: ${renderFormula(option.value)}` }} />
                    </div>
                );
            } else if (option.type === 'image') {
                return (
                    <div key={index}>
                        <Typography><strong>{option.name}:</strong></Typography>
                        <img src={option.value} alt={`option-${index}`} style={{ maxWidth: '100%' }} />
                    </div>
                );
            } else {
                return null;
            }
        });
    };


    const renderFormula = (text) => {
        const element = document.createElement('div');
        element.innerHTML = text;

        const formulas = element.querySelectorAll('.ql-formula');
        formulas.forEach(formula => {
            const formulaText = formula.getAttribute('data-value');
            const renderedFormula = katex.renderToString(formulaText, {
                throwOnError: false
            });
            formula.innerHTML = renderedFormula;
        });

        return element.innerHTML;
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <AdminHeader />
            <AdminMenuDrawer />
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Container maxWidth="lg">
                    <Typography variant="h4" gutterBottom>
                        AI Admin Page - English
                    </Typography>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} sm={4} md={2}>
                            <Select
                                fullWidth
                                value={selectedTag}
                                onChange={handleTagChange}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    태그 선택
                                </MenuItem>
                                {TAGS.map((tag, index) => (
                                    <MenuItem key={index} value={tag}>
                                        {tag}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Select
                                fullWidth
                                value={selectedDetailedTag}
                                onChange={handleDetailedTagChange}
                                displayEmpty
                                disabled={!selectedTag || !DETAILED_TAGS[selectedTag]}
                            >
                                <MenuItem value="" disabled>
                                    세부 태그 선택
                                </MenuItem>

                                {selectedTag && DETAILED_TAGS[selectedTag] && Object.keys(DETAILED_TAGS[selectedTag]).map((detailedTag, index) => (
                                    <MenuItem key={index} value={detailedTag}>
                                        {detailedTag}

                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        {selectedTag && selectedDetailedTag && Array.isArray(DETAILED_TAGS[selectedTag][selectedDetailedTag]) && (
                            <Grid item xs={12} sm={4} md={2}>
                                <Select
                                    fullWidth
                                    value={selectedSubDetailedTag}
                                    onChange={(e) => setSelectedSubDetailedTag(e.target.value)}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        하위 태그 선택
                                    </MenuItem>
                                    {DETAILED_TAGS[selectedTag][selectedDetailedTag].map((subTag, index) => (
                                        <MenuItem key={index} value={subTag}>
                                            {subTag}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={4} md={2}>
                            <Select
                                fullWidth
                                value={difficulty}
                                onChange={(e) => setDifficulty(e.target.value)}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    난이도 선택
                                </MenuItem>
                                <MenuItem value="하">하</MenuItem>
                                <MenuItem value="중">중</MenuItem>
                                <MenuItem value="상">상</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Select
                                fullWidth
                                value={questionType}
                                onChange={(e) => setQuestionType(e.target.value)}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    질문 유형 선택
                                </MenuItem>
                                <MenuItem value="multiple choice">Multiple Choice</MenuItem>
                                <MenuItem value="short answer">Short Answer</MenuItem>
                            </Select>
                        </Grid>

                        <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                fullWidth
                                label="Number of Questions"
                                type="number"
                                value={numQuestions}
                                onChange={(e) => setNumQuestions(parseInt(e.target.value, 10) || 1)}
                                inputProps={{ min: 1 }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={includeImage}
                                        onChange={(e) => setIncludeImage(e.target.checked)}
                                    />
                                }
                                label="Include Image"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleGenerateQuestion}
                                disabled={loading}
                            >
                                질문 생성
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {loading && <CircularProgress />}
                            {questions.length > 0 && questions.map((q, index) => (
                                <Box key={q.id || index} mt={3}>
                                    <Typography variant="h6"><strong>Content:</strong></Typography>
                                    {renderContent(q)}
                                    <Typography variant="h6"><strong>Question Text:</strong></Typography>
                                    <div dangerouslySetInnerHTML={{ __html: renderFormula(q.questionText) }} className="prose" />
                                    <Typography variant="h6"><strong>Options:</strong></Typography>
                                    {renderOptions(q)}
                                    <Typography variant="h6"><strong>Answer:</strong> {q.answer}</Typography>
                                    <Typography variant="h6"><strong>Explanation:</strong></Typography>
                                    <div dangerouslySetInnerHTML={{ __html: renderFormula(q.explanation) }} className="prose" />
                                    <Typography variant="h6"><strong>Difficulty:</strong> {difficultyMapping[q.difficulty]}</Typography>
                                    <Typography variant="h6"><strong>Tag:</strong> {q.tag}</Typography>
                                    <Typography variant="h6"><strong>Detailed Tag:</strong> {q.detailed_tag}</Typography>
                                    {q.sub_detailed_tag && <Typography variant="h6"><strong>Sub-Detailed Tag:</strong> {q.sub_detailed_tag}</Typography>}
                                    <Typography variant="h6"><strong>Question Type:</strong> {q.questionType}</Typography>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleEditClick(q)}
                                        sx={{ mt: 2 }}
                                        startIcon={<EditIcon />}
                                    >
                                        수정하기
                                    </Button>
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="lg">
                <DialogTitle>문제 수정</DialogTitle>
                <DialogContent>
                    <DialogContentText>문제의 세부 사항을 수정합니다.</DialogContentText>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Difficulty</Typography>
                        <Select
                            name="difficulty"
                            value={editQuestion?.difficulty || '중'}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            <MenuItem value="상">상</MenuItem>
                            <MenuItem value="중">중</MenuItem>
                            <MenuItem value="하">하</MenuItem>
                        </Select>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Question Type</Typography>
                        <Select
                            name="questionType"
                            value={editQuestion?.questionType || 'multiple choice'}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            <MenuItem value="multiple choice">Multiple Choice</MenuItem>
                            <MenuItem value="short answer">Short Answer</MenuItem>
                        </Select>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Content</Typography>
                        {(editQuestion?.content || []).map((item, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, border: '1px solid gray', p: 1 }}>
                                <Select
                                    value={item.type}
                                    onChange={(e) => handleContentChange(index, 'type', e.target.value)}
                                    sx={{ mr: 2 }}
                                >
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="image">Image</MenuItem>
                                </Select>
                                {item.type === 'text' ? (
                                    <ReactQuill
                                        value={item.value}
                                        onChange={(content) => handleContentChange(index, 'value', content)}
                                        modules={{
                                            toolbar: [
                                                [{ 'size': [] }, { 'font': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['clean'], [{ 'formula': 'formula' }],
                                                [{ 'color': [] }, { 'background': [] }],
                                            ],
                                            clipboard: {
                                                matchVisual: false,
                                            },
                                        }}
                                        formats={[
                                            'size', 'font',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent', 'color', 'background', 'formula'
                                        ]}
                                        className="prose quill-editor"
                                    />
                                ) : (
                                    <>
                                        <TextField
                                            label="Value"
                                            value={item.value}
                                            onChange={(e) => handleContentChange(index, 'value', e.target.value)}
                                            fullWidth
                                        />
                                        <input
                                            accept="image/*"
                                            type="file"
                                            onChange={handleFileChange}
                                        />
                                        <Button onClick={() => handleUploadClick('content', index, 'content')} color="primary" variant="contained" sx={{ mt: 2 }}>
                                            Upload Image to S3
                                        </Button>
                                    </>
                                )}
                                <IconButton onClick={() => handleRemoveContent(index)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Button onClick={handleAddContent} color="primary" startIcon={<AddIcon />}>
                            Add Content
                        </Button>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Question Text</Typography>
                        <TextField
                            label="Question Text"
                            value={editQuestion?.questionText || ''}
                            onChange={(e) => setEditQuestion(prev => ({ ...prev, questionText: e.target.value }))}
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Options</Typography>
                        {(editQuestion?.options || []).map((option, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2, border: '1px solid gray', p: 1 }}>
                                <Select
                                    value={option.type}
                                    onChange={(e) => handleOptionChange(index, 'type', e.target.value)}
                                    sx={{ mr: 2 }}
                                >
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="image">Image</MenuItem>
                                </Select>
                                {option.type === 'text' ? (
                                    <ReactQuill
                                        value={option.value}
                                        onChange={(content) => handleOptionChange(index, 'value', content)}
                                        modules={{
                                            toolbar: [
                                                [{ 'size': [] }, { 'font': [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['clean'], [{ 'formula': 'formula' }],
                                                [{ 'color': [] }, { 'background': [] }],
                                            ],
                                            clipboard: {
                                                matchVisual: false,
                                            },
                                        }}
                                        formats={[
                                            'size', 'font',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent', 'color', 'background', 'formula'
                                        ]}
                                        className="prose quill-editor"
                                    />
                                ) : (
                                    <>
                                        <TextField
                                            label="Image URL"
                                            value={option.value}
                                            onChange={(e) => handleOptionChange(index, 'value', e.target.value)}
                                            fullWidth
                                        />
                                        <input
                                            accept="image/*"
                                            type="file"
                                            onChange={handleFileChange}
                                        />
                                        <Button onClick={() => handleUploadClick(option.name, index, 'option')} color="primary" variant="contained" sx={{ mt: 2 }}>
                                            Upload Image to S3
                                        </Button>
                                    </>
                                )}
                                <IconButton onClick={() => handleRemoveOption(index)} color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Button onClick={handleAddOption} color="primary" startIcon={<AddIcon />}>
                            Add Option
                        </Button>
                    </Box>
                    <TextField
                        margin="dense"
                        name="answer"
                        label="Answer"
                        value={editQuestion?.answer || ''}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Explanation</Typography>
                        <ReactQuill
                            value={editQuestion?.explanation || ''}
                            onChange={(content) => setEditQuestion(prev => ({ ...prev, explanation: content }))}
                            modules={{
                                toolbar: [
                                    [{ 'size': [] }, { 'font': [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                                    ['clean'], [{ 'formula': 'formula' }],
                                    [{ 'color': [] }, { 'background': [] }],
                                ],
                                clipboard: {
                                    matchVisual: false,
                                },
                            }}
                            formats={[
                                'size', 'font',
                                'bold', 'italic', 'underline', 'strike', 'blockquote',
                                'list', 'bullet', 'indent', 'color', 'background', 'formula'
                            ]}
                            className="prose quill-editor"
                        />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Tag</Typography>
                        <Select
                            name="tag"
                            value={editQuestion?.tag || ''}
                            onChange={(e) => {
                                handleInputChange(e);
                            }}
                            fullWidth
                        >
                            {TAGS.map((tag, index) => (
                                <MenuItem key={index} value={tag}>
                                    {tag}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Detailed Tag</Typography>
                        <Select
                            name="detailed_tag"
                            value={editQuestion?.detailed_tag || ''}
                            onChange={(e) => {
                                handleInputChange(e);
                                setEditQuestion(prev => ({
                                    ...prev,
                                    sub_detailed_tag: ''
                                }));
                            }}
                            fullWidth
                            disabled={!editQuestion?.tag}
                        >
                            {editQuestion?.tag && DETAILED_TAGS[editQuestion.tag] && Object.keys(DETAILED_TAGS[editQuestion.tag]).map((detailedTag, index) => (
                                <MenuItem key={index} value={detailedTag}>
                                    {detailedTag}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    {editQuestion?.tag && editQuestion?.detailed_tag && DETAILED_TAGS[editQuestion.tag][editQuestion.detailed_tag] && DETAILED_TAGS[editQuestion.tag][editQuestion.detailed_tag].length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="h6">Sub-Detailed Tag</Typography>
                            <Select
                                name="sub_detailed_tag"
                                value={editQuestion?.sub_detailed_tag || ''}
                                onChange={handleInputChange}
                                fullWidth
                            >
                                {DETAILED_TAGS[editQuestion.tag][editQuestion.detailed_tag].map((subTag, index) => (
                                    <MenuItem key={index} value={subTag}>
                                        {subTag}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveClick} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AIAdminPage;
