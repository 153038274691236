import React from 'react';
import { Box, Toolbar, Container, Typography } from '@mui/material';
import EnglishGeneratedQuestionsTable from './EnglishGeneratedQuestionsTable';
import EnglishGeneratedQuestionDetailTable from './EnglishGeneratedQuestionDetailTable';
import AdminHeader from '../AdminHeader';
import AdminMenuDrawer from '../AdminMenuDrawer';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const EnglishGeneratedAdminPage = () => {

    const navigate = useNavigate();

    const handlePageChange = (page, state) => {
        navigate(`/admin/${page}`, { state });
    };


    return (
        <Box sx={{ display: 'flex' }}>
            <AdminHeader />
            <AdminMenuDrawer />
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Container maxWidth="lg">
                    <Typography variant="h4" gutterBottom>
                        English Generated Questions Management
                    </Typography>
                    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Routes>
                            <Route path="/" element={<EnglishGeneratedQuestionsTable onPageChange={handlePageChange}/>} />
                            <Route path="/:id" element={<EnglishGeneratedQuestionDetailTable onPageChange={handlePageChange}/>} />
                        </Routes>
                    </Container>
                </Container>
            </Box>
        </Box>
    );
};

export default EnglishGeneratedAdminPage;
