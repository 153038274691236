import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination, MenuItem, Select, FormControl, InputLabel, Button, Box, FormControlLabel, Checkbox
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import { useApi } from '../../../contexts/Apicontext';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from '../../Loading/Loading';

const TAGS = [
    "Algebra",
    "Advanced Math",
    "Problem-Solving and Data Analysis",
    "Geometry and Trigonometry"
];

const DETAILED_TAGS = {
    "Algebra": [
        "Linear equations in one variable",
        "Linear functions in one variable",
        "Linear equations in two variables",
        "Systems of two linear equations in two variables",
        "Linear inequalities in one or two variables"
    ],
    "Advanced Math": [
        "Equivalent expressions",
        "Nonlinear equations in one variable and systems of equations in two variables",
        "Nonlinear functions"
    ],
    "Problem-Solving and Data Analysis": [
        "Ratios, rates, proportional relationships, and units",
        "Percentages",
        "One-variable data: Distributions and measures of center and spread",
        "Two-variable data: Models and scatterplots",
        "Probability and conditional probability",
        "Inference from sample statistics and margin of error",
        "Evaluating statistical claims: Observational studies and experiments"
    ],
    "Geometry and Trigonometry": [
        "Area and volume",
        "Lines, angles, and triangles",
        "Right triangles and trigonometry",
        "Circles"
    ]
};

const MathGeneratedQuestionsTable = () => {
    const location = useLocation();
    const apiBaseUrl = useApi();
    const navigate = useNavigate();

    // location.state로부터 page, rowsPerPage, selectedTag, selectedDetailedTag, showImageOnly 가져오기
    const initialPage = location.state?.page;
    const initialRowsPerPage = location.state?.rowsPerPage;
    const initialSelectedTag = location.state?.selectedTag;
    const initialSelectedDetailedTag = location.state?.selectedDetailedTag;
    const initialShowImageOnly = location.state?.showImageOnly;

    const [questions, setQuestions] = useState([]);
    const [totalQuestions, setTotalQuestions] = useState(0);

    const [page, setPage] = useState(() => {
        // URL 파라미터 우선, 없으면 state, 그래도 없으면 0
        const savedPage = parseInt(new URLSearchParams(window.location.search).get('page'), 10);
        if (!isNaN(savedPage)) return savedPage;
        if (typeof initialPage === 'number') return initialPage;
        return 0;
    });

    const [rowsPerPage, setRowsPerPage] = useState(() => {
        const savedRowsPerPage = parseInt(new URLSearchParams(window.location.search).get('rowsPerPage'), 10);
        if (!isNaN(savedRowsPerPage)) return savedRowsPerPage;
        if (typeof initialRowsPerPage === 'number') return initialRowsPerPage;
        return 10;
    });

    const [selectedTag, setSelectedTag] = useState(initialSelectedTag || '');
    const [selectedDetailedTag, setSelectedDetailedTag] = useState(initialSelectedDetailedTag || '');
    const [showImageOnly, setShowImageOnly] = useState(initialShowImageOnly || false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchQuestions();
    }, [page, rowsPerPage, selectedTag, selectedDetailedTag, showImageOnly]);

    const fetchQuestions = async () => {
        setLoading(true); 
        const token = localStorage.getItem('token');
        const url = new URL(`${apiBaseUrl}/admin/generated-questions-math`);
        url.searchParams.append('page', page + 1);
        url.searchParams.append('limit', rowsPerPage);

        if (selectedTag) {
            url.searchParams.append('tag', selectedTag);
        }

        if (selectedDetailedTag) {
            url.searchParams.append('detailedTag', selectedDetailedTag);
        }

        if (showImageOnly) {
            url.searchParams.append('showImageOnly', showImageOnly);
        }

        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setQuestions(data.questions);
            setTotalQuestions(data.totalQuestions);
        } catch (error) {
            console.error('Error fetching questions:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleMoreClick = (question) => {
        // 상세 페이지로 이동할 때도 state로 현재 필터 상태와 페이지 정보 넘김
        navigate(`/admin/MATH_AI_LIST/${question.GeneratedQuestionID}`, {
            state: {
                rawquestion: question,
                page,
                rowsPerPage,
                selectedTag,
                selectedDetailedTag,
                showImageOnly
            }
        });
    };

    const handleDeleteClick = async (questionID) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this question?');
        if (confirmDelete) {
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiBaseUrl}/admin/generated-questions-math/${questionID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                alert('Deleted! Question has been deleted.');
                fetchQuestions();
            } else {
                alert('Error! Failed to delete question.');
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        updateUrlParams(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        updateUrlParams(0, newRowsPerPage);
    };

    const updateUrlParams = (newPage, newRowsPerPage) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('page', newPage);
        urlParams.set('rowsPerPage', newRowsPerPage);
        window.history.replaceState(null, '', `?${urlParams.toString()}`);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className='w-full'>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
                <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel id="tag-select-label">Tag</InputLabel>
                    <Select
                        labelId="tag-select-label"
                        value={selectedTag}
                        onChange={(e) => {
                            setSelectedTag(e.target.value);
                            setSelectedDetailedTag('');
                        }}
                        label="Tag"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {TAGS.map(tag => (
                            <MenuItem key={tag} value={tag}>{tag}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel id="detailed-tag-select-label">Detailed Tag</InputLabel>
                    <Select
                        labelId="detailed-tag-select-label"
                        value={selectedDetailedTag}
                        onChange={(e) => setSelectedDetailedTag(e.target.value)}
                        label="Detailed Tag"
                        disabled={!selectedTag}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {(DETAILED_TAGS[selectedTag] || []).map(detailedTag => (
                            <MenuItem key={detailedTag} value={detailedTag}>{detailedTag}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showImageOnly}
                            onChange={(e) => setShowImageOnly(e.target.checked)}
                            color="primary"
                        />
                    }
                    label="이미지 포함 문제만 보기"
                />
            </Box>

            <TableContainer component={Paper} sx={{ width: '100%' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Question</TableCell>
                            <TableCell>Difficulty</TableCell>
                            <TableCell>Tags</TableCell>
                            <TableCell>Detailed Tags</TableCell>
                            <TableCell sx={{ position: 'sticky', right: 0 }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {questions.map((question) => (
                            <TableRow key={question.GeneratedQuestionID}>
                                <TableCell>{question.GeneratedQuestionID}</TableCell>
                                <TableCell>
                                    {question.content && question.content.length > 0 ? (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: question.content[0].value.length > 15
                                                    ? `${question.content[0].value.substring(0, 15)}...`
                                                    : question.content[0].value
                                            }}
                                        />
                                    ) : 'No content'}
                                </TableCell>
                                <TableCell>{question.difficulty}</TableCell>
                                <TableCell>{question.tags}</TableCell>
                                <TableCell>{question.detailedTags}</TableCell>
                                <TableCell sx={{ position: 'sticky', right: 0 }}>
                                    <IconButton onClick={() => handleMoreClick(question)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteClick(question.GeneratedQuestionID)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={totalQuestions}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default MathGeneratedQuestionsTable;