import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import MainPageHeader from './MainPageHeader';
import MainPageBody from './MainPageBody';
import MainPageFooter from './MainPageFooter';
import { Box } from '@mui/material';
import MyProfile from './MyProfile';
import CoursesPage from './Courses/CoursesPage';
import MyProgress from './MyProgress';
import BoardCommon from './Board/BoardCommon';

export default function MainPage({ currentRoute }) {
    const addKakaoChannel = () => {
        const Kakao = window.Kakao;

        if (Kakao) {
            if (!Kakao.isInitialized()) {
                Kakao.init(process.env.KAKAO_CHAT_KEY);
            }
            Kakao.Channel.createChatButton({
                container: '#kakao-talk-channel-chat-button', // 올바른 요소 ID
                channelPublicId: '_yxlyDxj',
                size: 'small',
                color: 'yellow',
                shape: 'pc',
                supportMultipleDensities: true,
                title: 'consult',
            });
        }
    };

    const handleChatButtonClick = () => {
        const kakaoAppUrl = 'kakaotalk://launch/profile/_yxlyDxj'; // 카카오톡 앱 프로토콜 URL
        const fallbackUrl = 'https://pf.kakao.com/_yxlyDxj/chat'; // 카카오톡 웹 채팅 페이지 URL

        // PC 환경에서 카카오톡 앱 열기 시도
        window.location.href = kakaoAppUrl;

        // 1초 뒤 웹 브라우저에서 대체 URL로 연결 시도 (카카오톡 앱이 열리지 않을 경우 대비)
        setTimeout(() => {
            window.open(fallbackUrl, '_blank');
        }, 1000);
    };

    useEffect(() => {
        addKakaoChannel();

        // 클릭 이벤트 리스너 추가
        const chatButton = document.getElementById('kakao-talk-channel-chat-button');
        if (chatButton) {
            chatButton.addEventListener('click', handleChatButtonClick);
        }

        // 클린업 함수로 이벤트 리스너 제거
        return () => {
            if (chatButton) {
                chatButton.removeEventListener('click', handleChatButtonClick);
            }
        };
    }, []);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Header */}
            <MainPageHeader />

            {/* Body */}
            <Box sx={{ flexGrow: 1 }}>
                {currentRoute === "myprofile" ? (
                    <MyProfile />
                ) : currentRoute === "courses" ? (
                    <CoursesPage /> // courses 경로일 때 CoursesPage를 렌더링
                ) : currentRoute === "myprogress" ? (
                    <MyProgress />
                ) : currentRoute === "board" ? (
                    <BoardCommon />
                ) : (
                    <MainPageBody />
                )}
            </Box>

            {/* 카카오톡 채널 채팅 버튼을 렌더링할 요소 */}
            <div 
                id='kakao-talk-channel-chat-button' 
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    zIndex: 1000 // 다른 컴포넌트들보다 위에 오도록 설정
                }}
            ></div>

            {/* 구분선 추가 */}
            <Divider sx={{ my: 2 }} />

            {/* Footer */}
            <MainPageFooter />
        </Box>
    );
}
